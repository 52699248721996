.admin-dashboard aside {
  height: 100vh;
  overflow: auto;
  background-color: #1a1a1b;
  border-color: #3c403f;
}

.admin-dashboard aside::-webkit-scrollbar {
  display: none;
}
.admin-dashboard aside .avatar-aside {
  color: #aeb2b1;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.9px;
  word-wrap: break-word;
}

.admin-dashboard header .logout-btn {
  color: white;
  font-size: 12px;
  font-family: "Arabic Pro Bold";
  line-height: 16.2px;
  word-wrap: break-word;
  background: transparent;
  border: 1px #6FCF17 solid;
}

.admin-dashboard header > h1 {
  color: white;
  font-size: 16px;
  font-family: "Arabic Pro Reg";
  line-height: 21.6px;
  word-wrap: break-word;
}

/** BUG: DASHBOARD AND WALLET ARE ACTIVE AT THE SAME TIME**/
.admin-dashboard aside a.active {
  /* background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
}

.admin-dashboard aside a.active svg path {
  /* fill: #111010; */
  fill: #ffff;
}
.admin-dashboard aside a.strokeactive svg path {
  /* stroke: #111010; */
  fill: #ffff;
  fill: transparent;
}
.admin-dashboard aside a.active h1 {
  /* color: #111010; */
  color: #ffff;
}
.admin-dashboard .top-body-area > h1 {
  color: white;
  font-size: 24px;
  font-family: "Arabic Pro Medium";
  line-height: 32.4px;
  word-wrap: break-word;
}
.admin-dashboard .top-body-area > button {
  /* color: #aeb2b1; */
  font-size: 16px;
  font-family: "Arabic Pro Reg";
  line-height: 21.6px;
  word-wrap: break-word;
}

/*========= HOME BUTTON NAV ==========*/
.admin-dashboard .top-body-area > button span {
  color:  #6FCF17;
}
.admin-dashboard .agent-wrapper {
  background-color: #1a1a1b;
  min-height: 841px;
}
.admin-dashboard .agent-wrapper-dashboard {
  min-height: unset;
}

.admin-dashboard .top-agent h1 {
  color: white;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}
.admin-dashboard .top-agent p {
  color: #aeb2b1;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.admin-dashboard .top-agent .input-container-top > div {
  height: 50px;
  width: 222px;
  background-color: #111010;
  border-radius: 8px;
  display: flex;
  padding-right: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid #3c403f;
}
.admin-dashboard .top-agent .input-container-top select {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}
.admin-dashboard .top-agent-page {
  border-bottom: 1px solid #3c403f;
}
.admin-dashboard .top-agent-page button {
  /* background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  /* color: #111010; */
  color: #ffff;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  line-height: 18.9px;
  word-wrap: break-word;
  border: 1px solid transparent;
}
.admin-dashboard .bottom-agent .input-container > label {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.admin-dashboard .bottom-agent .input-wrapper {
  background-color: #111010;
  border: 1px solid #3c403f;
  height: 50px;
}
.admin-dashboard .bottom-agent .input-wrapper select {
  color: white;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
  height: 100%;
  width: 100%;
  background: #111010;
  outline: none;
  cursor: pointer;
}

.admin-dashboard .create-agents form .input-wrapper-textarea .input-container {
  height: 101px;
}

.admin-dashboard .top-agent-page button.outline-btn {
  border-color: #6FCF17;
  background: transparent;
  color: #fff;
}

.admin-dashboard .bottom-agent .input-wrapper input,
.admin-dashboard .input-wrapper-textarea textarea {
  color: white;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}
.admin-dashboard .bottom-agent > button {
  background-color: #252525;
  height: 50px;
  color: #aeb2b1;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  line-height: 18.9px;
  word-wrap: break-word;
}
.admin-dashboard .bottom-agent > button.active {
  border-bottom: 1px solid #6FCF17;
  color: #ffff;
}
.admin-dashboard .bottom-agent > button:last-child,
.admin-dashboard .bottom-agent-page > button.csv-black {
  /* background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  /* color: #111010; */
  color: #ffff;
}
.admin-dashboard .bottom-agent-page > button:last-child,
.admin-dashboard .bottom-agent-page > button.filter {
  height: 50px;
  background: #2e2e2e;
  color: #fff;
}
.admin-dashboard .bottom-agent-page > button.csv-black {
  /* background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
  /* color: #111010; */
  color: #ffff;
}
.admin-dashboard .activity-row {
  background-color: #1a1a1b;
}
.admin-dashboard .activity-row .top-area > h1 {
  color: white;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}

.admin-dashboard .box-activity {
  background-color: #252525;
}
.admin-dashboard .box-activity .top-box {
  border-bottom: 1px solid #4d515a;
}
.admin-dashboard .box-activity .top-box > h1 {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}

.admin-dashboard .bottom-box {
  background-color: #2e2e2e;
}
.admin-dashboard .bottom-box h1 {
  color: #aeb2b1;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.admin-dashboard .bottom-box p {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}

.admin-dashboard .activity-box-content-row {
  height: 29px;
  border-bottom: 1px solid #4d515a;
}
.admin-dashboard .activity-box-content-row p {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Reg";
  line-height: 16.2px;
  word-wrap: break-word;
}
.admin-dashboard .charts-wrapper .chart {
  background-color: #1a1a1b;
  height: 272px;
}
.admin-dashboard .charts-wrapper .chart .chart-top > h1 {
  color: white;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}

.admin-dashboard .charts-wrapper .chart .chart-top {
  border-bottom: 1px solid #4d515a;
}
.admin-dashboard .chart-bottom > .badge:first-child span {
  background-color: #7cb5ec;
}
.admin-dashboard .chart-bottom > .badge:nth-child(2) span {
  background-color: #f7a35c;
}
.admin-dashboard .chart-bottom > .badge:nth-child(3) span {
  background-color: #8085e9;
}
.admin-dashboard .chart-bottom > .badge:nth-child(4) span {
  background-color: #f15c80;
}
.admin-dashboard .chart-bottom > .badge:nth-child(5) span {
  background-color: #e4d354;
}
.admin-dashboard .chart-bottom > .badge:nth-child(6) span {
  /* background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);
}
.admin-dashboard .chart-bottom > .badge:last-child span {
  background-color:  #6FCF17;
}
.admin-dashboard .charts-wrapper .chart .chart-bottom p {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Reg";
  line-height: 16.2px;
  word-wrap: break-word;
}

.admin-dashboard .charts-wrapper .chartbgchange {
  background: #2f3033;
}

.admin-dashboard .AgentTable {
  width: 100%;
}
.admin-dashboard .AgentTable td {
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
  height: 40px;
  text-align: left;
  border: 1px solid #3c403f;
  padding-left: 9px;
}
.admin-dashboard .AgentTable th {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
  height: 40px;
  text-align: left;
  border: 1px solid #3c403f;
  padding-left: 9px;
}
.admin-dashboard .AgentTable td .view-request-link {
  text-decoration: underline;
}
.admin-dashboard .AgentTable td .view-request-btn {
  color: #6FCF17;
  font-size: 12px;
  width: 93px;
  font-family: "Arabic Pro Reg";
  line-height: 16.2px;
  border-radius: 2px;
  word-wrap: break-word;
  background: linear-gradient(
    180deg,
    rgba(255, 200.27, 61.62, 0.1) 0%,
    rgba(255, 182.83, 0, 0.1) 49%,
    rgba(208.25, 149.31, 0, 0.1) 100%
  );
  border-radius: 2px;
  border: 1px #6FCF17 solid;
}
.admin-dashboard .AgentTable th {
  font-family: "Arabic Pro Bold";
}
.admin-dashboard .AgentTable tr:hover td {
  background-color: #252525;
}

.admin-dashboard .pagination-table button p {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}
.admin-dashboard .pagination-table button.active p {
  color: #fff;
}
.admin-dashboard .pagination-table button.active svg path {
  fill: #fff;
}
.admin-dashboard .pagination-table button.count {
  color: #aeb2b1;
  font-size: 10px;
  font-family: "Arabic Pro Medium";
  line-height: 13.5px;
  word-wrap: break-word;
}
.admin-dashboard .pagination-table button.count.active {
  background-color: #2e2e2e;
  color: #fff;
  /* border: 1px solid  #6FCF17; */
  border: 1px solid  #6FCF17;
  padding: 6px 10px;
}

/* create-agents */
.admin-dashboard .create-agents .top-agent {
  background-color: #252525;
}
.admin-dashboard .create-agents form .input-container {
  height: 50px;
  background-color: #111010;
  border-radius: 8px;
  display: flex;
  padding-right: 24px;
  align-items: center;
  justify-content: center;
}

.admin-dashboard .create-agents form .input-container input {
  flex: 1;
  height: 100%;
  padding-left: 24px;
  width: 100%;
  background: transparent;
  border: none;
  color: #aeb2b1;

  font-size: 12px;
  outline: none;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}
.admin-dashboard .create-agents form .input-wrapper label {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.RadioCustom label {
  border-radius: 99px;
  border-color: #aeb2b1;
}

.RadioCustom input:checked + label {
  background-color: #aeb2b1;
}
.admin-dashboard .create-agents form .input-wrapper label span {
  color: #d71a1a;
}
.admin-dashboard .create-agents form .input-wrapper label span + span {
  color: #aeb2b1;
}
.admin-dashboard .create-agents form .input-container select {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  padding-left: 24px;
  outline: none;
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}

.admin-dashboard .buttons-wrapper button {
  /* color: #111010; */
  color: #ffff;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  line-height: 18.9px;
  word-wrap: break-word;
  /* background: linear-gradient(136.6deg, #5df9c2 9.33%, #3ac192 88.1%); */
  background: linear-gradient(144deg, #9BE402 -47.44%, #1FA83C 140.74%);

  border: 1px solid transparent;
}

.admin-dashboard .buttons-wrapper button.outline-button {
  background: transparent;
  color: #fff;
  border: 1px solid #6FCF17;
}

.admin-dashboard .checkbox-para {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  word-wrap: break-word;
  transform: translateY(3px);
}
.admin-dashboard .delete-btn {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  word-wrap: break-word;
  background: rgba(255, 198, 54, 0.1);
  border-radius: 4px;
  border: 1px #6FCF17 solid;
  height: 28px;
  width: 68px;
}

.admin-dashboard .title-checkbox {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  line-height: 18.9px;
  word-wrap: break-word;
}
.admin-dashboard .title-checkbox span {
  color: #d71a1a;
}
.admin-dashboard .form-wrapper-outline-down {
  border: 1px solid #3c403f;
}
.admin-dashboard .form-wrapper-outline-up {
  border: 1px solid #3c403f;
  border-bottom: none;
}
.admin-dashboard .form-wrapper-outline-up > h1 {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Bold";
  line-height: 16.2px;
  word-wrap: break-word;
}
aside .bonus-area:not(.sub-bonus-area) {
  min-height: 43px;
}
aside .sub-bonus-area {
  width: 181px;
  min-height: 34px;
}
aside .sub-bonus-area h1 {
  font-size: 12px;
}

.credentialsedit {
  color: #6FCF17;
  font-size: 12px;
  font-family: "Arabic Pro Reg";
  line-height: 16.2px;
  word-wrap: break-word;
  background: linear-gradient(
    180deg,
    rgba(255, 200.27, 61.62, 0.1) 0%,
    rgba(255, 182.83, 0, 0.1) 49%,
    rgba(208.25, 149.31, 0, 0.1) 100%
  );
  border-radius: 2px;
  border: 1px #6FCF17 solid;
  width: 55px;
}

.menutablepara {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}
.browser-file {
  height: 34px;
  width: 65px;
  background-color: #252525;
  border: 1px solid #3c403f;
}

.belowcreateinputtext {
  color: #aeb2b1;
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 16.2px;
  word-wrap: break-word;
}
.belowcreateinputtext span {
  color: #fff;
}

.file-display > h1 {
  color: #6FCF17;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  line-height: 18.9px;
  word-wrap: break-word;
}
.file-display > img {
  width: 205px;
  height: 161px;
}

.UserRegistrationTable thead .text-center {
  text-align: center;
}

.UserRegistrationTable .checkbox-user label {
  border-color: #aeb2b1;
  border-radius: 50%;
}

.UserRegistrationTable .checkbox-user input:checked + label {
  border-color: #6FCF17;
}
.delete-popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
}

.delete-popup .bg-popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -1;
  background: rgba(0, 0, 0, 0.26);
}
.delete-popup > * {
  background-color: #212632;
  width: 40%;
  max-width: 100%;
}
.delete-popup > * h1 {
  color: white;
  font-size: 24px;
  font-family: "Arabic Pro Medium";
  line-height: 32.4px;
  word-wrap: break-word;
}
.delete-popup > * p {
  color: white;
  font-size: 15px;
  font-family: "Arabic Pro Reg";
  line-height: 32.4px;
  word-wrap: break-word;
}
.delete-popup .buttons-wrapper button.del {
  background: #dc3545 !important;
  color: #fff;
}
.percountry-img {
  height: 150px;
}
@media screen and (max-width: 1150px) {
  .admin-dashboard > *:first-child {
    position: fixed;
    z-index: 99999;
    left: -100%;
    transition: 0.3s ease all;
  }
  .admin-dashboard > *:first-child.active {
    left: 0px;
  }
  .burger-icon {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .charts-wrapper {
    grid-template-columns: 1fr;
  }
  .charts-wrapper > * {
    height: unset !important;
  }
  .agent-wrapper .bottom-agent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .agent-wrapper .bottom-agent > *:first-child,
  .agent-wrapper .bottom-agent > *:last-child {
    grid-column: span 2;
  }
  .admin-dashboard-agent {
    display: block;
  }
  .admin-dashboard .forms-wrapper form {
    grid-template-columns: 1fr;
  }
  .admin-dashboard .forms-wrapper form > * {
    grid-column: span 1;
  }
  .admin-dashboard .forms-wrapper form > *:last-child {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
  .admin-dashboard .forms-wrapper form > *:last-child > *:first-child.flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .admin-dashboard-agent .agent-wrapper .bottom-agent > * {
    grid-column: span 2;
    width: 100%;
  }
  .admin-dashboard-agent .agent-wrapper .table-wrapper {
    overflow: auto;
  }
  .admin-dashboard-agent .agent-wrapper .table-wrapper > table {
    min-width: 1000px;
  }
  .admin-dashboard .forms-wrapper form > *.grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 600px) {
  .activity-row > .grid {
    grid-template-columns: 1fr;
  }
  .admin-dashboard + footer .copyright-area {
    flex-direction: column;
    gap: 20px;
  }
  .admin-dashboard .body-area-admin .top-body-area button:last-child {
    display: none;
  }

  .admin-dashboard-agent .top-agent-page {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.react-calendar__navigation .react-calendar__navigation__prev2-button,
.react-calendar__navigation .react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation button span {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #252525;
}
.react-calendar__navigation {
  margin-bottom: 0px !important;
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 10px !important;
}
.react-calendar__month-view__days button {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #aeb0b0;
  border-radius: 5px;
}
.react-calendar__tile--now {
  background: transparent !important;
}
.react-calendar {
  border: none !important;
  border-radius: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #111010 !important;
  z-index: 9999 !important;
  color: #fff !important;
  position: absolute;
  left: 0px;
  top: 100%;
}
.react-calendar .react-calendar__tile {
  font-size: 12px !important;
}
.react-calendar__navigation button span {
  color: #fff !important;
}
.react-calendar__tile--active {
  background: #36a7f5 !important;
  color: #fff !important;
}
.react-calendar__navigation button,
.react-calendar .react-calendar__tile {
  background: transparent !important;
}
.react-calendar .react-calendar__tile--range {
  border: 1px solid #5cf7c0 !important;
  background: transparent !important;
  color: #111010 !important;
  background-color: #5cf7c0 !important;
}
.react-calendar__tile {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.react-calendar {
  width: 205px;
}
