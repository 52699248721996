.SigninLanding {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.SigninLanding .inner-work-sign-in {
  background: rgba(33, 38, 50, 0.7);
  border-radius: 24px;
  overflow: hidden;
  border: 2px rgba(255, 255, 255, 0.4) solid;
  backdrop-filter: blur(24px);
  width: 597px;
  height: 738px;
  padding-top: 58px;
  padding-bottom: 58px;
}
.SigninLanding .inner-work-sign-in form {
  width: 444px;
}
.SigninLanding .inner-work-sign-in form > p {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.SigninLanding .inner-work-sign-in form > .logo {
  width: 197px;
}
.SigninLanding .inner-work-sign-in form .input-wrapper label {
  color: white;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}

.SigninLanding .inner-work-sign-in form .input-wrapper input {
  background-color: #1f242f;
}

.SigninLanding .inner-work-sign-up {
  height: 880px;
}
.SigninLanding .FlagSelect .head {
  background-color: #1f242f;
}

.SigninLanding .FlagSelect .list-flag-select ul {
  border: 1px #323743 solid;
}
.SigninLanding .FlagSelect .list-flag-select li {
  background-color: #1f242f;
  border: 1px #323743 solid;
}
.SigninLanding .FlagSelect .list-flag-select {
  border: 1px #323743 solid;
  background-color: #1f242f;
}
.SigninLanding .inner-work-sign-in .tabs-wrapper a {
  border-radius: 8px;
  border: 1px solid var(--innersecondaryColor);
  color: var(--paragraphColor);
  background: transparent;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  line-height: 18.2px;
  height: 50px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-work-sign-in .tabs-wrapper a.active {
  background: var(--innersecondaryColor);
  color: var(--headingColor);
  border-bottom: 1px #ffc83e solid;
}

@media screen and (max-height: 880px) {
  .SigninLanding,
  .SigninLanding .LayerUp {
    position: static;
    height: 100%;
    min-height: 100vh;
  }
  .SigninLanding .LayerUp {
    padding: 4rem 0rem;
  }
}
@media screen and (max-width: 640px) {
  .SigninLanding .LayerUp {
    padding: 0rem;
    min-height: 100vh;
    padding: 2rem 0rem;
  }

  .SigninLanding .inner-work-sign-in {
    /* border-radius: 0px; */
    /* border: none; */
    width: 90%;
  }
  .SigninLanding .inner-work-sign-in .right-side {
    width: 90%;
  }
  .SigninLanding .inner-work-sign-in form {
    width: 100%;
    padding-left: 0px;
  }
}
